import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseInternalComponent } from '../../../../../../goldstar-share/src/app/modules/base/component/base-internal/base-internal.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../models/classes';
import { ApplyMarginOnBodyAction, NavbarTitleUpdatedAction } from '../../../store/store.actions';
import { applyMarginOnBody } from '../../../store/store.selector';
import { EditorStyle, LabelMode } from 'devextreme/common';

@Component({
	selector: 'app-base-layout',
	templateUrl: './base-layout.component.html',
	styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent extends BaseInternalComponent implements OnInit, AfterViewInit, OnChanges {
	protected showSpinner!: boolean;
	protected stylingMode: EditorStyle = 'outlined';
	protected labelMode: LabelMode = 'hidden';

	@Input() headerImage: boolean = false;
	layoutWithMargin: boolean = true;

	constructor(
		public elRef?: ElementRef,
		public store?: Store<AppState>
	) {
		super();

		this.store?.dispatch(ApplyMarginOnBodyAction({ applyMargin: true }));

		this.store?.select(applyMarginOnBody).subscribe((applyMargin: boolean) => {
			this.layoutWithMargin = applyMargin;
		});

		// The base header title updates for all screens
		this.store?.dispatch(NavbarTitleUpdatedAction({ title: '' }));
	}

	formHeaderTitle!: string;

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		this.headerImage = this.headerImage;
	}

	ngAfterViewInit(): void {}
}
